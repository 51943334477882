// Import Statements
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/sv';

import FormSection from './FormSection';

dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);
dayjs.locale('sv');

// Utility Functions
// Function to find the next Monday
const getNextMonday = () => {
  const today = dayjs();
  const nextMonday = today.isoWeekday(8); // ISO weekdays, where Monday is 1 and Sunday is 7, so next Monday is 8
  return nextMonday;
};

// List of reasons
const reasons = [
  'Semester',
  'Sjukdom',
  'Tjänsteresa',
  'Personliga skäl',
  'Arbetar på annan plats',
];

// List of email monitoring options
const emailMonitoringOptions = [
  'Läser mail sporadiskt',
  'Läser mail regelbundet',
  'Kommer inte att läsa mail',
  'Endast akutärenden via telefon',
];

// Validation Schema
const validationSchema = Yup.object().shape({
  userName: Yup.string().when('includeGreetings', {
    is: true,
    then: (schema) => schema.required('Ditt namn är obligatoriskt'),
  }),
  customDate: Yup.date().required('Datum tillbaka är obligatoriskt om det är valt'),
  contactPersonName: Yup.string().when('includeContactPerson', {
    is: true,
    then: (schema) => schema.required('Kontaktpersonens namn är obligatoriskt'),
  }),
  contactPersonEmail: Yup.string().email('Ogiltig e-postadress').when('includeContactPerson', {
    is: true,
    then: (schema) => schema.required('Kontaktpersonens e-post är obligatoriskt'),
  }),
  emailMonitoring: Yup.string().when('includeEmailMonitoring', {
    is: true,
    then: (schema) => schema.required('Välj hur du kommer att bevaka din inkorg'),
  }),
});

// Function to generate the auto-reply message
const generateMessage = (values) => {
  let message = `Hej,\n\nTack för ditt meddelande. Jag är för närvarande inte tillgänglig`;

  if (values.includeReason) {
    message += ` på grund av ${values.reason.toLowerCase()}`;
  }

  if (values.includeEndDate) {
    const returnDate = dayjs(values.customDate).format('dddd [den] D MMMM YYYY');
    message += `.\n\nJag är tillbaka ${returnDate}.`;
  } else {
    message += `.`;
  }

  if (values.includeEmailMonitoring) {
    message += `\n\n${values.emailMonitoring}`;
  }

  if (values.includeContactPerson) {
    message += `\n\nFör brådskande ärenden, vänligen kontakta ${values.contactPersonName} på ${values.contactPersonEmail}.`;
  }

  if (values.includeGreetings) {
    message += `\n\n\nMed vänliga hälsningar,\n${values.userName}`;
  }

  return message;
};

// Main Component
const App = () => {
  const [copied, setCopied] = useState(false);
  const nextMonday = getNextMonday().format('YYYY-MM-DD');

  const handleCopyToClipboard = (message) => {
    navigator.clipboard.writeText(message);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#b3e5fc', minHeight: '100vh' }}>
      <Typography variant="h4" component="h1" sx={{ marginBottom: 3 }}>
        Autosvar Generator
      </Typography>
      <Formik
        initialValues={{
          userName: '',
          includeEndDate: true,
          customDate: nextMonday,
          includeReason: false,
          reason: '',
          includeContactPerson: false,
          contactPersonName: '',
          contactPersonEmail: '',
          includeGreetings: false,
          includeEmailMonitoring: false,
          emailMonitoring: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const message = generateMessage(values);
          handleCopyToClipboard(message);
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* Form Controls */}
                <FormSection
                  label="Inkludera datum tillbaka"
                  checked={values.includeEndDate}
                  handleChange={(e) => setFieldValue('includeEndDate', e.target.checked)}
                  name="includeEndDate"
                >
                  <Field
                    name="customDate"
                    type="date"
                    as={TextField}
                    label="Datum tillbaka"
                    onChange={handleChange}
                    value={values.customDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    margin="normal"
                    sx={{ backgroundColor: 'white' }}
                  />
                </FormSection>

                <FormSection
                  label="Inkludera anledning"
                  checked={values.includeReason}
                  handleChange={(e) => setFieldValue('includeReason', e.target.checked)}
                  name="includeReason"
                >
                  <Field
                    name="reason"
                    as={TextField}
                    select
                    label="Anledning"
                    onChange={handleChange}
                    value={values.reason}
                    fullWidth
                    margin="normal"
                    sx={{ backgroundColor: 'white' }}
                  >
                    {reasons.map((reason) => (
                      <MenuItem key={reason} value={reason}>
                        {reason}
                      </MenuItem>
                    ))}
                  </Field>
                </FormSection>

                <FormSection
                  label="Inkludera kontaktperson"
                  checked={values.includeContactPerson}
                  handleChange={(e) => setFieldValue('includeContactPerson', e.target.checked)}
                  name="includeContactPerson"
                >
                  <Box>
                    <Field
                      name="contactPersonName"
                      as={TextField}
                      label="Kontaktpersonens namn"
                      onChange={handleChange}
                      value={values.contactPersonName}
                      fullWidth
                      margin="normal"
                      sx={{ backgroundColor: 'white' }}
                    />
                    <Field
                      name="contactPersonEmail"
                      as={TextField}
                      label="Kontaktpersonens e-post"
                      onChange={handleChange}
                      value={values.contactPersonEmail}
                      fullWidth
                      margin="normal"
                      sx={{ backgroundColor: 'white' }}
                    />
                  </Box>
                </FormSection>

                <FormSection
                  label="Inkludera hälsningar"
                  checked={values.includeGreetings}
                  handleChange={(e) => setFieldValue('includeGreetings', e.target.checked)}
                  name="includeGreetings"
                >
                  <Field
                    name="userName"
                    as={TextField}
                    label="Ditt namn"
                    onChange={handleChange}
                    value={values.userName}
                    fullWidth
                    margin="normal"
                    sx={{ backgroundColor: 'white' }}
                  />
                </FormSection>

                <FormSection
                  label="Inkludera hur du bevakar inkorgen"
                  checked={values.includeEmailMonitoring}
                  handleChange={(e) => setFieldValue('includeEmailMonitoring', e.target.checked)}
                  name="includeEmailMonitoring"
                >
                  <FormControl fullWidth>
                    <Field
                      name="emailMonitoring"
                      as={Select}
                      label="Bevaka inkorg"
                      onChange={handleChange}
                      value={values.emailMonitoring}
                      fullWidth
                      sx={{ backgroundColor: 'white' }}
                    >
                      {emailMonitoringOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <ErrorMessage name="emailMonitoring" component="div" />
                </FormSection>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ position: 'relative', marginBottom: 2, height: '100%' }}>
                  <Typography variant="h6">Förhandsgranska Autosvar</Typography>
                  <TextareaAutosize
                    minRows={8}
                    value={generateMessage(values)}
                    readOnly
                    style={{
                      width: '100%',
                      backgroundColor: 'white',
                      padding: '10px',
                      fontSize: '16px',
                      boxSizing: 'border-box',
                    }}
                  />
                  <Box sx={{ position: 'absolute', top: 8, right: 8, display: 'flex', alignItems: 'center' }}>
                    {copied && (
                      <Box sx={{ display: 'flex', alignItems: 'center', color: 'green', marginRight: 1 }}>
                        <CheckCircleOutlineIcon />
                        <Typography sx={{ marginLeft: 1 }}>Kopierat!</Typography>
                      </Box>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => handleCopyToClipboard(generateMessage(values))}
                    >
                      Kopiera
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default App;