import React from 'react';
import { Field, ErrorMessage } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FormSection = ({ label, checked, handleChange, name, children }) => (
  <Box sx={{ marginBottom: 2 }}>
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          name={name}
        />
      }
      label={label}
    />
    {checked && children}
  </Box>
);

export default FormSection;